import React from 'react'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import useStyles from './contact-styles'

export default function MembershipForm() {
  var classes = useStyles()
  const [state, setState] = React.useState({
    contact: '',
    email: '',
    famName: '',
    address: '',
    city: '',
    state: '',
    zip: '',
  })

  function isDisabled() {
    return (
      state.contact === '' ||
      state.email === '' ||
      state.famName === '' ||
      state.address === '' ||
      state.city === '' ||
      state.state === '' ||
      state.zip === ''
    )
  }

  function handleChange(e) {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    })
  }

  return (
    <>
      <Typography className={classes.heading} variant="h1">
        Member Form
      </Typography>
      <form
        className={classes.form}
        name="membership"
        method="POST"
        data-netlify="true"
      >
        <TextField
          required
          id="famName"
          label="Family Name"
          name="famName"
          variant="filled"
          className={classes.input}
          onChange={handleChange}
          required={true}
        />
        <TextField
          required
          id="email"
          label="Email"
          name="email"
          variant="filled"
          className={classes.input}
          onChange={handleChange}
          required={true}
        />
        <TextField
          required
          id="address"
          label="Street Address"
          name="address"
          variant="filled"
          className={classes.input}
          onChange={handleChange}
          required={true}
        />
        <TextField
          required
          id="city"
          label="City"
          name="city"
          variant="filled"
          className={classes.input}
          onChange={handleChange}
          required={true}
        />
        <TextField
          required
          id="state"
          label="State"
          name="state"
          variant="filled"
          className={classes.input}
          onChange={handleChange}
          required={true}
        />
        <TextField
          required
          id="zip"
          label="Zip Code"
          name="zip"
          variant="filled"
          className={classes.input}
          onChange={handleChange}
          required={true}
        />
        <TextField
          required
          id="contact"
          label="Contact Name"
          name="contact"
          variant="filled"
          className={classes.input}
          onChange={handleChange}
          required={true}
        />
        <Typography id="meeting-label" className={classes.label}>
          Are you interested in attending the monthly membership board
          meetings?*
        </Typography>
        <RadioGroup
          aria-labelledby="meeting-label"
          name="meeting"
          value={state.meeting}
          onChange={handleChange}
        >
          <FormControlLabel
            value="yes"
            control={<Radio required className={classes.radio} />}
            label="Yes"
          />
          <FormControlLabel
            value="no"
            control={<Radio required className={classes.radio} />}
            label="No"
          />
        </RadioGroup>
        <input type="hidden" name="form-name" value="membership" />
        <Button
          className={classes.button}
          type="submit"
          variant="contained"
          disableFocusRipple
          disabled={isDisabled()}
        >
          Submit
        </Button>
      </form>
    </>
  )
}
