export function addTypographyClasses(html) {
  let result = html

  let liRegex = /<li>/g
  let aRegex = /<a/g
  let pRegex = /<p>/g
  let h1Regex = /<h1>/g
  let h2Regex = /<h2>/g
  let h3Regex = /<h3>/g
  let h4Regex = /<h4>/g
  let h5Regex = /<h5>/g
  let h6Regex = /<h6>/g

  result = result.replace(
    pRegex,
    `<p class="MuiTypography-root MuiTypography-body1">`
  )
  result = result.replace(
    h1Regex,
    `<h1 class="MuiTypography-root MuiTypography-h1">`
  )
  result = result.replace(
    h2Regex,
    `<h2 class="MuiTypography-root MuiTypography-h2">`
  )
  result = result.replace(
    h3Regex,
    `<h3 class="MuiTypography-root MuiTypography-h3">`
  )
  result = result.replace(
    h4Regex,
    `<h4 class="MuiTypography-root MuiTypography-h4">`
  )
  result = result.replace(
    h5Regex,
    `<h5 class="MuiTypography-root MuiTypography-h5">`
  )
  result = result.replace(
    h6Regex,
    `<h6 class="MuiTypography-root MuiTypography-h6">`
  )
  result = result.replace(
    aRegex,
    `<a class="MuiTypography-root MuiTypography-body1" `
  )
  result = result.replace(
    liRegex,
    `<li class="MuiTypography-root MuiTypography-body1">`
  )

  return result
}
