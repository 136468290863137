import React from 'react'
import useStyles from './events-styles'
import { graphql, useStaticQuery, navigate } from 'gatsby'
import Typography from '@material-ui/core/Typography'

var eventsQuery = graphql`
  query EventsQuery {
    gcms {
      events {
        title
        date
        slug
        img {
          alt
          image {
            url
          }
        }
      }
    }
  }
`

export default function Events() {
  var classes = useStyles()
  var data = useStaticQuery(eventsQuery)
  var events = data.gcms.events.map(event => <Event event={event} />)
  return (
    <>
      <Typography className={classes.title} variant="h2">
        Events
      </Typography>
      <div className={classes.eventsContainer}>{events}</div>
    </>
  )
}

function Event({ event }) {
  var classes = useStyles()
  var date = new Date(event.date)
  return (
    <a
      className={classes.event}
      onClick={() => {
        navigate(`events/${event.slug}`)
      }}
    >
      <img
        src={event.img.image.url}
        alt={event.img.alt}
        className={classes.eventThumbnail}
      ></img>
      <Typography className={classes.eventTitle} variant="h6" component="h3">
        {event.title}
      </Typography>
      {event.date && (
        <Typography className={classes.eventTitle} variant="subtitle2">
          {`${getMonth(date)} ${date.getDate()}, ${date.getFullYear()}`}
        </Typography>
      )}
    </a>
  )
}

function getMonth(date) {
  switch (date.getMonth()) {
    case 0:
      return 'January'
    case 1:
      return 'February'
    case 2:
      return 'March'
    case 3:
      return 'April'
    case 4:
      return 'May'
    case 5:
      return 'June'
    case 6:
      return 'July'
    case 7:
      return 'August'
    case 8:
      return 'September'
    case 9:
      return 'October'
    case 10:
      return 'November'
    case 11:
      return 'December'
    default:
      break
  }
}
