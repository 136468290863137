import React from 'react'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Carousel } from 'react-responsive-carousel'
import styled from '@emotion/styled'
import Resources from 'components/resources/resources'
import ParentResources from 'components/resources/parent-resources'
import ContactForm from 'components/forms/contact'
import Layout from 'components/layout/layout'
import Section from 'components/section/section'
import Events from 'components/events-list/events-list'
import { addTypographyClasses } from 'utils/functions'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import Typography from '@material-ui/core/Typography'
import useStyles from './page-styles'

export default function Page({ pageContext }) {
  var theme = useTheme()
  var smScreen = theme.breakpoints.down('md')
  var classes = useStyles()
  var { page } = pageContext

  var Main = styled.div`
    padding: 64px;

    p {
      margin-bottom: ${theme.spacing(2)}px;
    }

    a {
      color: ${theme.palette.primary.light};
    }
  `

  var Image = styled.img`
    height: 480px;
    width: auto !important;

    ${smScreen} {
      height: auto;
      max-height: 360px;
      max-width: 400px;
    }
  `

  var sectionIncrement = 0
  return (
    <Layout title={page.title}>
      {/* {page.carousel && (
        <div className={classes.carouselContainer}>
          <Carousel
            autoPlay={true}
            showStatus={false}
            infiniteLoop={true}
            showThumbs={false}
          >
            {page.carousel.map(slide => {
              if (slide.image) {
                return (
                  <div>
                    <Image src={slide.image.url} />
                  </div>
                )
              }
              return null
            })}
          </Carousel>
        </div>
      )} */}
      {page.slug !== 'home' ? (
        <Typography variant="h1">{page.title}</Typography>
      ) : (
        <>
          <div className={classes.carouselContainer}>
            <Carousel
              autoPlay={true}
              showStatus={false}
              infiniteLoop={true}
              showThumbs={false}
            >
              {page.carousel.map(slide => {
                if (slide.image) {
                  return (
                    <div>
                      <Image src={slide.image.url} />
                    </div>
                  )
                }
                return null
              })}
            </Carousel>
          </div>
          <Events />
        </>
      )}
      {page.content && page.content.html !== '<p></p>' && (
        <Main
          dangerouslySetInnerHTML={{
            __html: addTypographyClasses(page.content.html),
          }}
        />
      )}
      {page.slug === 'contact' && <ContactForm />}
      {page.slug === 'resources' && <Resources />}
      {page.sections.map(section => {
        let side
        if (sectionIncrement % 2 === 0) {
          side = 'right'
        } else {
          side = 'left'
        }
        sectionIncrement++
        return <Section section={section} side={side} />
      })}
      {page.slug === 'parents' && <ParentResources />}
    </Layout>
  )
}
