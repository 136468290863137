import React from 'react'
import { useTheme } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Checkbox from '@material-ui/core/Checkbox'
import Button from '@material-ui/core/Button'
import useStyles from './contact-styles'

export default function ContactForm() {
  var classes = useStyles()
  const [state, setState] = React.useState({
    name: '',
    email: '',
    phone: '',
    message: '',
    firstCall: false,
    welcomeKit: false,
  })

  function handleChange(e) {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    })
  }

  function handleCheck(e) {
    setState({
      ...state,
      [e.target.name]: !state[e.target.name],
    })
  }

  function isDisabled() {
    const { name, email, phone, message } = state
    return name === '' || email === '' || phone === '' || message === ''
  }

  return (
    <>
      <form
        className={classes.form}
        name="contact"
        method="POST"
        data-netlify="true"
      >
        <TextField
          required
          id="name"
          label="Name"
          name="name"
          variant="filled"
          onChange={handleChange}
          className={classes.input}
          required={true}
        />
        <TextField
          required
          id="email"
          label="Email"
          name="email"
          variant="filled"
          onChange={handleChange}
          className={classes.input}
          required={true}
        />
        <TextField
          required
          id="phone"
          label="Phone"
          name="phone"
          variant="filled"
          onChange={handleChange}
          className={classes.input}
          required={true}
        />
        <TextField
          required
          id="message"
          label="Message"
          name="message"
          variant="filled"
          multiline
          onChange={handleChange}
          className={classes.input}
          required={true}
        />

        <Typography className={classes.label}>
          <label htmlFor="firstCall">
            Would you like to be contacted by a First Call Volunteer?
          </label>
        </Typography>
        <Checkbox
          id="firstCall"
          name="firstCall"
          onChange={handleCheck}
          checked={state.firstCall}
        />

        <Typography className={classes.label}>
          <label htmlFor="welcomeKit">Would you like a welcome kit?</label>
        </Typography>
        <Checkbox
          id="welcomeKit"
          name="welcomeKit"
          onChange={handleCheck}
          checked={state.welcomeKit}
        />

        <input type="hidden" name="form-name" value="contact" />
        <Button
          type="submit"
          variant="contained"
          className={classes.button}
          disableFocusRipple
          disabled={isDisabled()}
        >
          Submit
        </Button>
      </form>
    </>
  )
}
