import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  title: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  event: {
    marginBottom: theme.spacing(2),
  },
  eventTitle: {
    width: '225px',
  },
  eventThumbnail: {
    height: '150px',
    borderRadius: '4px',
    border: '1px solid #fff',
    width: '225px',
    objectFit: 'cover',
    objectPosition: 'top',
  },
  eventsContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
    width: '100%',
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    overflow: 'scroll',
  },
}))

export default useStyles
