import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  category: {
    borderRadius: '4px',
    backgroundColor: theme.palette.secondary.main,
    padding: '1em',
    margin: '2em',
  },
  resources: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
  },
  resource: {
    borderRadius: '4px',
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.contrastText,
    padding: '0.5em',
  },
}))

export default useStyles
