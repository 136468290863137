import React from 'react'
import styled from '@emotion/styled'
import useStyles from './resources-styles'
import Typography from '@material-ui/core/Typography'
import { graphql, useStaticQuery } from 'gatsby'
import { findDOMNode } from 'react-dom'

var resourcesQuery = graphql`
  query ResourcesQuery {
    gcms {
      resources {
        title
        category
        description
        url
      }
    }
  }
`

var resourceGroups = {
  activities: {
    title: 'Activities',
    values: [],
  },
  adult_support: {
    title: 'Adult Support',
    values: [],
  },
  new_parents: {
    title: 'New Parents',
    values: [],
  },
  social_support: {
    title: 'Social Support',
    values: [],
  },
  family_support: {
    title: 'Family Support',
    values: [],
  },
  educational: {
    title: 'Educational',
    values: [],
  },
  medical_developmental: {
    title: 'Medical / Developmental',
    values: [],
  },
  reading_list: {
    title: 'Reading List',
    values: [],
  },
}

export default function Resources() {
  var classes = useStyles()
  var data = useStaticQuery(resourcesQuery).gcms.resources
  data.forEach(resource => {
    resourceGroups[resource.category].values.push(resource)
  })
  var categories = Object.keys(resourceGroups).map(key => {
    let category = resourceGroups[key]
    if (category.values.length > 0) {
      return <Category title={category.title} list={category.values} />
    }
  })

  return <div className={classes.root}>{categories}</div>
}

export function Category({ title, list }) {
  var classes = useStyles()

  return (
    <section aria-label={title} className={classes.category}>
      <Typography variant="h2">{title}</Typography>
      <div className={classes.resources}>
        {list.map(resource => (
          <Resource resource={resource} />
        ))}
      </div>
    </section>
  )
}

function Resource({ resource }) {
  var classes = useStyles()
  var Link = styled.a`
    margin: 1em;

    ${resource.url !== null &&
      `&:hover {
      h3 {
        text-decoration: underline;
      }
    }`}
  `

  return (
    <Link href={resource.url} target="_blank">
      <div className={classes.resource}>
        <Typography variant="h4" component="h3">
          {resource.title}
        </Typography>
        <Typography>{resource.description}</Typography>
      </div>
    </Link>
  )
}
