import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  dialog: {
    // '& .MuiDialog-paper': { width: '100%' },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& .MuiFormControl-root': { width: '100%' },
  },
  label: {
    width: '100%',
    marginTop: '32px',
  },
  input: {},
}))

export default useStyles
