import React, { useEffect } from 'react'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Link } from 'gatsby'
import { addTypographyClasses } from 'utils/functions'
// import styled from '@emotion/styled'
import ReactPlayer from 'react-player'
import FormButton from 'components/forms/form-button'
import Typography from '@material-ui/core/Typography'
import useStyles from './section-styles'

export default function Section({ section, side = 'right' }) {
  var theme = useTheme()
  var lgScreen = useMediaQuery(`(min-width:${theme.breakpoints.values.lg}px)`)
  var smScreen = !useMediaQuery(`(min-width:${theme.breakpoints.values.md}px)`)
  var circle = true

  var classes = useStyles({
    video: !!section.video,
    img: !!section.img,
    lgScreen,
    side,
    circle,
  })

  return (
    <div className={classes.root}>
      <div className={`${classes.sectionBody}`}>
        {(() => {
          if (circle) {
            if (smScreen || side === 'right') {
              return (
                <>
                  <div className={classes.text}>
                    <Typography className={classes.title} variant="h2">
                      {section.title}
                    </Typography>
                    <div
                      className={classes.content}
                      dangerouslySetInnerHTML={{
                        __html: addTypographyClasses(section.content.html),
                      }}
                    />
                    <div className={classes.formButtons}>
                      {section.forms &&
                        section.forms.map(form => (
                          <Link to={`/${form}`} className={classes.formButton}>
                            <Typography>Open Form</Typography>
                          </Link>
                        ))}
                    </div>
                  </div>
                  {section.img && (
                    <img
                      className={classes.image}
                      src={section.img.image.url}
                      alt={section.img.alt}
                    />
                  )}
                </>
              )
            }
            return (
              <>
                {section.img && (
                  <img
                    className={classes.image}
                    src={section.img.image.url}
                    alt={section.img.alt}
                  />
                )}
                <div className={classes.text}>
                  <Typography className={classes.title} variant="h2">
                    {section.title}
                  </Typography>
                  <div
                    className={classes.content}
                    dangerouslySetInnerHTML={{
                      __html: addTypographyClasses(section.content.html),
                    }}
                  />
                  <div className={classes.formButtons}>
                    {section.forms &&
                      section.forms.map(form => (
                        <Link to={`/${form}`} className={classes.formButton}>
                          <Typography>Open Form</Typography>
                        </Link>
                      ))}
                  </div>
                </div>
              </>
            )
          }
          return (
            <div className={classes.text}>
              <Typography className={classes.title} variant="h2">
                {section.title}
              </Typography>
              <div
                className={classes.content}
                dangerouslySetInnerHTML={{
                  __html: addTypographyClasses(section.content.html),
                }}
              />
            </div>
          )
        })()}
      </div>
      {!circle && section.img && (
        <img
          className={classes.image}
          src={section.img.image.url}
          alt={section.img.alt}
        />
      )}
      {section.video && (
        <ReactPlayer className={classes.player} url={section.video} />
      )}
    </div>
  )
}
