import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  // slideContainer: {
  //   display: 'flex',
  //   width: '100%',
  //   backgroundColor: theme.palette.secondary.main,
  // },
  // image: {
  //   maxWidth: '100%',
  // },
  carouselContainer: {
    display: 'flex',
    width: '100%',
    '& .carousel-root': {
      width: '100%',
    },
    '& .slide': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
}))

export default useStyles
