import React from 'react'
import { navigate } from 'gatsby'
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import FormLabel from '@material-ui/core/FormLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Select from '@material-ui/core/Select'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import useStyles from './contact-styles'
import { Typography } from '@material-ui/core'

export default function VolunteerForm({ handleClose }) {
  var classes = useStyles()
  const [state, setState] = React.useState({
    name: '',
    constituent: '',
    fName: '',
    lName: '',
    address: '',
    state: '',
    zip: '',
    email: '',
    phone: '',
    bWalk: false,
    board: false,
    events: false,
    other: false,
    specify: '',
    interest: '',
    experience: '',
  })

  function isDisabled() {
    let otherEmpty = false
    if (state.other && state.specify === '') {
      otherEmpty = true
    }

    return (
      state.name === '' ||
      state.constituent === '' ||
      state.fName === '' ||
      state.lName === '' ||
      state.address === '' ||
      state.state === '' ||
      state.zip === '' ||
      state.email === '' ||
      state.phone === '' ||
      otherEmpty
    )
  }

  function handleChange(e) {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    })
  }

  function handleCheck(e) {
    setState({
      ...state,
      [e.target.name]: !state[e.target.name],
    })
  }

  function encode(data) {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
  }

  return (
    <>
      <Typography className={classes.heading} variant="h1">
        Volunteer Form
      </Typography>
      <form
        className={classes.form}
        name="volunteer"
        method="POST"
        data-netlify="true"
      >
        <TextField
          id="name"
          label="Name of Individual/Group"
          name="name"
          variant="filled"
          className={classes.input}
          onChange={handleChange}
          required={true}
        />
        <FormControl
          className={`${classes.input} ${classes.select}`}
          required={true}
        >
          <InputLabel id="constituent-label">
            Primary Constituent Type
          </InputLabel>
          <Select
            labelId="constituent-label"
            id="constituent"
            name="constituent"
            variant="filled"
            value={state.constituent}
            onChange={handleChange}
          >
            <MenuItem value="NHDSA">NHDSA Member</MenuItem>
            <MenuItem value="school">School</MenuItem>
            <MenuItem value="student">Student</MenuItem>
            <MenuItem value="business">Business</MenuItem>
            <MenuItem value="community">Community Group</MenuItem>
            <MenuItem value="self">Self-Advocate</MenuItem>
            <MenuItem value="family">Family Member</MenuItem>
            <MenuItem value="educator">Educator</MenuItem>
            <MenuItem value="other">Other</MenuItem>
          </Select>
        </FormControl>
        <TextField
          id="fName"
          label="First Name"
          name="fName"
          variant="filled"
          className={classes.input}
          onChange={handleChange}
          required={true}
        />
        <TextField
          id="lName"
          label="Last Name"
          name="lName"
          variant="filled"
          className={classes.input}
          onChange={handleChange}
          required={true}
        />
        <TextField
          id="address"
          label="Street Address"
          name="address"
          variant="filled"
          className={classes.input}
          onChange={handleChange}
          required={true}
        />
        <TextField
          id="city"
          label="City"
          name="city"
          variant="filled"
          className={classes.input}
          onChange={handleChange}
          required={true}
        />
        <TextField
          id="state"
          label="State"
          name="state"
          variant="filled"
          className={classes.input}
          onChange={handleChange}
          required={true}
        />
        <TextField
          id="zip"
          label="Zip Code"
          name="zip"
          variant="filled"
          className={classes.input}
          onChange={handleChange}
          required={true}
        />
        <TextField
          id="email"
          label="Email"
          name="email"
          variant="filled"
          className={classes.input}
          onChange={handleChange}
          required={true}
        />
        <TextField
          id="phone"
          label="Phone Number"
          name="phone"
          variant="filled"
          className={classes.input}
          onChange={handleChange}
          required={true}
        />
        <FormControl
          component="fieldset"
          className={classes.label}
          required={true}
        >
          <FormLabel component="legend">
            Please select your volunteer interests:
          </FormLabel>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.bWalk}
                  onChange={handleCheck}
                  name="bWalk"
                />
              }
              label="Buddy Walk"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.board}
                  onChange={handleCheck}
                  name="board"
                />
              }
              label="Membership Board"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.events}
                  onChange={handleCheck}
                  name="events"
                />
              }
              label="Events (ie. Winter Carnival, World Down Syndrome Day celebration, Holiday party, etc.)"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.other}
                  onChange={handleCheck}
                  name="other"
                />
              }
              label="Other"
            />
          </FormGroup>
        </FormControl>
        {state.other && (
          <>
            <Typography className={classes.label}>
              <label htmlFor="specify">If other, please specify:</label>
            </Typography>
            <TextField
              id="specify"
              name="specify"
              variant="filled"
              className={classes.input}
              onChange={handleChange}
              required={true}
            />
          </>
        )}
        <Typography className={classes.label} required={true}>
          <label htmlFor="interest">
            What interested you in volunteering for the NHDSA?
          </label>
        </Typography>
        <TextField
          id="interest"
          name="interest"
          variant="filled"
          multiline
          className={classes.input}
          onChange={handleChange}
          required={true}
        />
        <Typography className={classes.label}>
          <label htmlFor="experience">
            Please list any experience you have working with people with Down
            syndrome or other relative experience
          </label>
        </Typography>
        <TextField
          id="experience"
          name="experience"
          variant="filled"
          multiline
          className={classes.input}
          onChange={handleChange}
          required={true}
        />
        <input type="hidden" name="form-name" value="volunteer" />
        <Button
          className={classes.button}
          type="submit"
          variant="contained"
          disableFocusRipple
          disabled={isDisabled()}
        >
          Submit
        </Button>
      </form>
    </>
  )
}
