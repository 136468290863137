import React from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import useStyles from './contact-styles'

export default function OrganizationalGrant({ handleClose }) {
  var classes = useStyles()
  const [state, setState] = React.useState({
    name: '',
    address: '',
    state: '',
    zip: '',
    contactName: '',
    contactPhone: '',
    reason: '',
    funds: false,
    why: '',
    time: '',
    past: false,
    how: '',
    mission: '',
  })

  function isDisabled() {
    const isFundsEmpty = false
    if (state.funds && state.why === '') {
      isFundsEmpty = true
    }
    const isPastEmpty = false
    if (state.past && state.how === '') {
      isPastEmpty = true
    }

    return (
      state.name === '' ||
      state.address === '' ||
      state.state === '' ||
      state.zip === '' ||
      state.contactName === '' ||
      state.contactPhone === '' ||
      state.time === '' ||
      state.mission === '' ||
      isFundsEmpty ||
      isPastEmpty
    )
  }

  function handleChange(e) {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    })
  }

  function handleCheck(e) {
    setState({
      ...state,
      [e.target.name]: !state[e.target.name],
    })
  }

  return (
    <>
      <Typography className={classes.heading} variant="h1">
        Organizational Grant Form
      </Typography>
      <form
        className={classes.form}
        name="organizationGrant"
        method="POST"
        data-netlify="true"
      >
        <TextField
          id="name"
          label="Name of Organization"
          name="name"
          variant="filled"
          className={classes.input}
          onChange={handleChange}
          required={true}
        />
        <TextField
          id="address"
          label="Street Address"
          name="address"
          variant="filled"
          className={classes.input}
          onChange={handleChange}
          required={true}
        />
        <TextField
          id="city"
          label="City"
          name="city"
          variant="filled"
          className={classes.input}
          required={true}
        />
        <TextField
          id="state"
          label="State"
          name="state"
          variant="filled"
          className={classes.input}
          onChange={handleChange}
          required={true}
        />
        <TextField
          id="zip"
          label="Zip Code"
          name="zip"
          variant="filled"
          className={classes.input}
          onChange={handleChange}
          required={true}
        />
        <TextField
          id="contactName"
          label="Contact Name and Position"
          name="contactName"
          multiline
          variant="filled"
          className={classes.input}
          onChange={handleChange}
          required={true}
        />
        <TextField
          id="contactPhone"
          label="Contact Phone Number"
          name="contactPhone"
          variant="filled"
          className={classes.input}
          onChange={handleChange}
          required={true}
        />

        <Typography className={classes.label}>
          <label htmlFor="reason">
            Reason for request (Be specific as to dollar request, program that
            is to be funded, program budget, people/community to be served)
          </label>
        </Typography>
        <TextField
          id="reason"
          name="reason"
          multiline
          variant="filled"
          className={classes.input}
          onChange={handleChange}
          required={true}
        />

        <Typography className={classes.label}>
          <label htmlFor="funds">
            Have funds been requested/received from other groups or
            organizations for this particular need?
          </label>
        </Typography>
        <Checkbox
          id="funds"
          name="funds"
          onChange={handleCheck}
          checked={state.funds}
        />

        {state.funds && (
          <>
            <Typography className={classes.label}>
              <label htmlFor="why">If so, why?</label>
            </Typography>
            <TextField
              id="why"
              name="why"
              multiline
              variant="filled"
              className={classes.input}
              onChange={handleChange}
              required={true}
            />
          </>
        )}

        <Typography className={classes.label}>
          <label htmlFor="time">
            Is funding needed by a specific time? Explain:
          </label>
        </Typography>
        <TextField
          id="time"
          name="time"
          multiline
          variant="filled"
          className={classes.input}
          onChange={handleChange}
          required={true}
        />

        <Typography className={classes.label}>
          <label htmlFor="past">
            Has your organization received a grant from the NHDSA in the past?
          </label>
        </Typography>
        <Checkbox
          id="past"
          name="past"
          onChange={handleCheck}
          checked={state.past}
        />

        {state.past && (
          <>
            <Typography className={classes.label}>
              <label htmlFor="how">
                If so, please explain how the funds were spent:
              </label>
            </Typography>
            <TextField
              id="how"
              name="how"
              multiline
              variant="filled"
              className={classes.input}
              onChange={handleChange}
              required={true}
            />
          </>
        )}

        <Typography className={classes.label}>
          <label htmlFor="mission">
            Please provide the history and mission of your organization, how
            funds were spent in the past years if you received a grant from us,
            and include your annual budget or report:
          </label>
        </Typography>
        <TextField
          id="mission"
          name="mission"
          multiline
          variant="filled"
          className={classes.input}
          onChange={handleChange}
          required={true}
        />

        <Typography className={classes.label}>
          Please be aware that the NHDSA may request a copy of your annual
          budget or report before approving this request.
        </Typography>

        <input type="hidden" name="form-name" value="organizationGrant" />
        <Button
          className={classes.button}
          type="submit"
          variant="contained"
          disableFocusRipple
          disabled={isDisabled()}
        >
          Submit
        </Button>
      </form>
    </>
  )
}
