import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    minHeight: '300px',

    '& p': {
      marginBottom: theme.spacing(2),
    },

    '& a p': {
      marginBottom: 0,
    },

    '& h2': {
      textAlign: 'center',
    },
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  sectionBody: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  text: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    padding: `0 ${theme.spacing(8)}px`,
  },
  image: props => {
    if (props.circle) {
      return {
        height: '600px',
        width: '300px',
        borderRadius:
          props.side === 'right' ? '300px 0 0 300px' : '0 300px 300px 0',
        objectFit: 'cover',

        [theme.breakpoints.down('sm')]: {
          padding: `${theme.spacing(4)}px 0`,
          borderRadius: '300px',
          height: '500px',
          width: '500px',
          alignSelf: 'center',
        },
      }
    }
    return {
      maxWidth: '90%',
      maxHeight: '500px',
      objectFit: 'contain',
    }
  },
  player: {
    maxWidth: '90vw !important',
    margin: props => {
      if (props.lgScreen) {
        return theme.spacing(4)
      }
      return 'auto'
    },
  },
  formButtons: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: theme.spacing(2),
  },
  formButton: {
    backgroundColor: 'white',
    color: `${theme.palette.primary.main} !important`,
    textDecoration: 'none',
    padding: theme.spacing(2),
    borderRadius: '16px',
  },
}))

export default useStyles
