import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Category } from './resources'

var parentResourcesQuery = graphql`
  query ParentResourcesQuery {
    gcms {
      resources(where: { category: new_parents }) {
        title
        category
        description
        url
      }
    }
  }
`

export default function ParentResources() {
  var data = useStaticQuery(parentResourcesQuery)
  return <Category title="New Parent Resources" list={data.gcms.resources} />
}
